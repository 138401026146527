import React, { useRef, useEffect, useState, Fragment } from "react";
import styled from "styled-components";
import { Grid, Row, Col } from "react-flexbox-grid";
import Typist, { TypistProps } from "react-typist";
import "react-typist/dist/Typist.css";
import { Link } from "react-router-dom";

import BgVideo from "assets/20174593-720.mp4";

const Background = styled.div`
  flex: 1;
  display: flex;
  position: relative;
  z-index: 0;
  justify-content: center;
  background: black;
  overflow: hidden;
  color: white;
  height: 100vh;
`;

const BackgroundVideo = styled.video`
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
  opacity: 0.3;
  min-width: 100%;
  min-height: 100%;
  width: auto;
  height: auto;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;

const BigText = styled.div`
  color: white;
  font-weight: bolder;
  font-size: 10vw;
  opacity: 1;
  transition: 100ms linear;
  @media (min-width: 1200px) {
    font-size: 8vw;
  }
`;

const CenterGrid = styled(Grid)`
  width: 100%;
  margin: 0 10%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: relative;
  z-index: 2;
`;

const BigRow = styled(Row)`
  height: 25%;
`;

const BigButton = styled(Link)`
  display: inline-block;
  background: transparent;
  color: white;
  padding: 1vw 2vw;
  font-size: 2vw;
  border: 2px white solid;
  border-radius: 10px;
  outline: none;
  cursor: pointer;
  transition: 300ms linear;
  min-width: 10%;
  text-decoration: none;
  text-align: center;
  &:hover {
    min-width: 75vw;
    background: rgba(255, 255, 255, 0.1);
  }
  &:active {
    transition-duration: 1ms;
    background: transparent;
  }
  @media (max-width: 768px) {
    min-width: 75vw;
  }
  @media (max-width: 900px) {
    padding: 2vw 4vw;
    font-size: 4vw;
  }
`;

const H1 = styled.h1`
  font-size: 2vw;
  font-weight: 700;
  margin: 0 auto;
  transition: 300ms linear;
  @media (max-width: 900px) {
    font-size: 5vw;
  }
`;

const H2 = styled.h2`
  font-weight: 300;
  margin: 0 auto;
  transition: 300ms linear;
  div {
    display: inline;
  }
  @media (max-width: 900px) {
    div {
      display: block;
    }
  }
`;

const typist: TypistProps = {
  avgTypingDelay: 40,
  cursor: {
    show: false
  }
};

const line1Words = ["Data", "Business", "Powerful", "Commercial", "Enterprise"];
const line2Words = [
  "Intelligence",
  "Decisions",
  "Information",
  "Reporting",
  "Analysis"
];

function shuffleArray(a: string[]) {
  for (let i = a.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1));
    [a[i], a[j]] = [a[j], a[i]];
  }
  return a;
}

function randomBetween(a: number, b: number) {
  const rnd = Math.floor(Math.random() * b) + a;
  return rnd;
}

const Home: React.FC = () => {
  const videoRef = useRef<any>(null);
  const [l1k, setL1k] = useState(10);
  const [l2k, setL2k] = useState(20);
  useEffect(() => {
    if (videoRef.current) {
      // videoRef.current.playbackRate = 0.7;
    }
  }, [videoRef]);

  return (
    <Background>
      <BackgroundVideo
        src={BgVideo}
        ref={videoRef}
        autoPlay
        loop
        muted
        playsInline
      />
      <CenterGrid>
        <BigRow middle="xs">
          <div className="wow fadeInDown">
            <H1>
              <strong>DATALECT</strong>
            </H1>
            <H2>
              <div>Fluent in the dialect of data. </div>
              <div>Specialising in Power BI.</div>
            </H2>
          </div>
        </BigRow>
        <BigRow bottom="xs">
          <Col>
            <BigText className="wow fadeIn">
              <Typist
                key={l1k}
                {...typist}
                onTypingDone={() => setL1k(l1k + 1)}
              >
                {shuffleArray(line1Words).map(word => [
                  <span>{word}</span>,
                  <Typist.Backspace
                    count={word.length}
                    delay={randomBetween(1000, 3000) + word.length * 70}
                  />
                ])}
              </Typist>
            </BigText>
          </Col>
        </BigRow>
        <BigRow top="xs">
          <Col>
            <BigText className="wow fadeIn">
              <Typist
                key={l2k}
                {...typist}
                cursor={{ show: true, blink: true, element: "." }}
                startDelay={600}
                onTypingDone={() => setL2k(l2k + 1)}
              >
                {shuffleArray(line2Words).map(word => [
                  <span>{word}</span>,
                  <Typist.Backspace
                    count={word.length}
                    delay={randomBetween(1000, 3000) + word.length * 70}
                  />
                ])}
              </Typist>
            </BigText>
          </Col>
        </BigRow>
        <BigRow middle="xs">
          <Col xs={12}>
            {/* <BigButton to="/about" className="wow fadeInUp">
              Learn More
            </BigButton> */}
          </Col>
        </BigRow>
      </CenterGrid>
    </Background>
  );
};

export default Home;
