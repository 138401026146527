import React, { useEffect } from "react";
import { Router, Route, Switch } from "react-router-dom";
import { createBrowserHistory } from "history";

import Home from "components/Home";
import About from "components/About";

const history = createBrowserHistory();

const App: React.FC = () => {
  useEffect(() => {
    try {
      new (window as any).WOW().init();
    } catch (e) {}
  }, []);
  return (
    <Router history={history}>
      <Switch>
        <Route exact path="/" component={Home} />
        <Route exact path="/about" component={About} />
      </Switch>
    </Router>
  );
};

export default App;
