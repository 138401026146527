import React from "react";
import styled from "styled-components";

// import AboutData from "assets/white-about-data.jpg";
// import AboutDecisions from "assets/white-about-decisions.jpg";
// import AboutFuture from "assets/white-about-future.jpg";

const AboutPage = styled.div`
  background: #f0f0f0;
  width: 100%;
`;

const AboutSection = styled.section`
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 90vh;
  padding-bottom: 10vh;
  overflow: hidden;
  position: relative;
  transition: min-height 300ms linear;
  &:first-child {
    padding-top: 5vh;
  }
  @media (max-width: 900px) {
    padding-top: 10vh;
  }
`;

const AboutImage = styled.img`
  position: absolute;
  z-index: 1;
  height: 80%;
  transition: 300ms linear;
  @media (max-width: 900px) {
    height: auto;
    width: 120%;
  }
  @media (max-width: 1200px) {
    height: auto;
    width: 100%;
  }
`;

const AboutImageLeft = styled(AboutImage)`
  left: 0;
`;

const AboutImageRight = styled(AboutImage)`
  right: 0;
`;

const BigText = styled.div`
  position: absolute;
  z-index: 2;
  text-transform: uppercase;
  font-weight: bolder;
  font-size: 5vw;
  top: 10vh;
  max-width: 30vw;
  transition: 300ms linear;
  @media (max-width: 900px) {
    top: 0vh;
    max-width: 60vw;
    font-size: 9vw;
  }
  text-shadow: 4px 4px white;
`;

const BigTextLeft = styled(BigText)`
  text-align: left;
  left: 10vw;
  @media (max-width: 900px) {
    left: 5vw;
  }
`;

const BigTextRight = styled(BigText)`
  text-align: right;
  right: 10vw;
  @media (max-width: 900px) {
    right: 5vw;
  }
`;

const BigTextCenter = styled(BigText)`
  top: auto;
  text-align: center;
  position: relative;
  max-width: 80vw;
  margin: 0 auto;
`;

const Highlight = styled.span`
  color: white;
  text-shadow: 4px 4px black;
`;

const Underline = styled.span`
  text-decoration: underline;
`;

const BigForm = styled.form`
  margin: 0 auto;
  width: 50vw;
  margin: 5vh auto 10vh auto;
  display: flex;
  flex-direction: column;
  @media (max-width: 1200px) {
    width: 60vw;
  }
  @media (max-width: 900px) {
    width: 70vw;
  }
`;

const BigInput = styled.input`
  background: white;
  border-radius: 6px;
  border: 3px black solid;
  /* box-shadow: 4px 4px black; */
  padding: 1.5vh 2.5vw;
  font-size: 1.5vw;
  outline: none;
  margin: 1vh auto;
  transition: 300ms linear;
  width: 80%;
  @media (max-width: 1200px) {
    font-size: 2vw;
  }
  @media (max-width: 900px) {
    font-size: 3vw;
  }
`;

const BigTextArea = styled.textarea`
  font-family: sans-serif;
  background: white;
  border-radius: 6px;
  border: 3px black solid;
  /* box-shadow: 4px 4px black; */
  padding: 1.5vh 2.5vw;
  font-size: 1.5vw;
  outline: none;
  margin: 1vh auto;
  transition: 300ms linear;
  resize: none;
  width: 80%;
  @media (max-width: 1200px) {
    font-size: 2vw;
  }
  @media (max-width: 900px) {
    font-size: 3vw;
  }
`;

const BigButton = styled.div`
  background: #b5f7d1;
  border-radius: 6px;
  border: 2px black solid;
  box-shadow: 4px 4px black;
  font-size: 1.5vw;
  padding: 1.5vh 2.5vw;
  outline: none;
  text-align: center;
  margin: 1vh auto;
  transition: 300ms linear;
  cursor: pointer;
  font-weight: bold;
  width: 80%;
  &:hover {
    background: white;
    box-shadow: 6px 6px black;
  }
  @media (max-width: 1200px) {
    font-size: 2vw;
  }
  @media (max-width: 900px) {
    font-size: 3vw;
  }
`;

const About: React.FC = () => {
  return (
    <AboutPage>
      <AboutSection>
        <BigTextLeft className="wow fadeInLeft">
          Let the <Underline>data</Underline> of the <Highlight>past</Highlight>
        </BigTextLeft>
        {/* <AboutImageRight src={AboutData} className="wow fadeIn" /> */}
      </AboutSection>
      <AboutSection>
        {/* <AboutImageLeft
          src={AboutDecisions}
          className="wow fadeIn"
          data-wow-delay="500ms"
        /> */}
        <BigTextRight className="wow fadeInRight">
          guide the <Underline>decisions</Underline> of{" "}
          <Highlight>today</Highlight>
        </BigTextRight>
      </AboutSection>
      <AboutSection>
        <BigTextLeft className="wow fadeInLeft">
          Shape the <Highlight>future</Highlight> of your{" "}
          <Underline>business</Underline>
        </BigTextLeft>
        {/* <AboutImageRight
          src={AboutFuture}
          className="wow fadeIn"
          data-wow-delay="500ms"
        /> */}
      </AboutSection>
      <BigTextCenter>
        Get results <Highlight>now</Highlight>
      </BigTextCenter>
      <BigForm>
        <BigInput placeholder="Name" />
        <BigInput placeholder="Email / Mobile" />
        <BigTextArea
          placeholder="Do you know what results you are trying to acheive?"
          rows={10}
        />
        <BigButton>Contact</BigButton>
      </BigForm>
      <div>c</div>
    </AboutPage>
  );
};

export default About;
